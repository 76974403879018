import { Divider, List, ListItemAvatar, ListItemButton, Typography } from '@mui/material';

import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import { Box } from '@mui/system';
import { iKeywordResponse } from '@/types/types.definition';

type IProps = {
  onSetSearchValue: (value: string) => void;
  items: iKeywordResponse[];
};

function SearchedKeywords({ onSetSearchValue, items }: IProps) {
  return (
    <>
      <Box sx={{ pt: 0, pb: 1 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" component="span">
          Recent searches
        </Typography>
      </Box>
      <Divider sx={{ my: 1 }} />
      {!!items.length && (
        <List disablePadding>
          {items.map((item) => (
            <ListItemButton key={item.id} onClick={() => onSetSearchValue(item.value)}>
              <ListItemAvatar>
                <AccessTimeTwoToneIcon />
              </ListItemAvatar>
              <Box flex="1">
                <Box display="flex" justifyContent="space-between">
                  <Typography>{item.value}</Typography>
                </Box>
              </Box>
            </ListItemButton>
          ))}
        </List>
      )}
    </>
  );
}

export default SearchedKeywords;
