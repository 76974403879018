import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import CancelledPage from '@/pages/CancelledPage';
import CollectedPage from '@/pages/CollectedPage';
import DefaultLayout from '@/layouts/DefaultLayout';
import DeliveredPage from '@/pages/DeliveredPage';
import ErrorPage from '@/pages/ErrorPage';
import LoginCallbackPage from '@/pages/LoginCallbackPage';
import LoginPage from '@/pages/LoginPage';
import NewRequestPage from '@/pages/NewRequestPage';
import OrderDetailPage from '@/pages/OrderDetailPage';
import OrderTrackingPage from '@/pages/OrdersTrackingPage';
import PointHistoryPage from '@/pages/PointHistoryPage';
import PointPage from '@/pages/PointPage';
import RequestsPage from '@/pages/RequestsPage';
import SelectMenuPage from '@/pages/SelectMenuPage';
import SubLayout from '@/layouts/SubLayout';
import WaitingsPage from '@/pages/WaitingsPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<DefaultLayout />} errorElement={<ErrorPage />}>
        <Route path="/" element={<SelectMenuPage />} />
        <Route path="point" element={<PointPage />} />
        <Route path="tracking" element={<OrderTrackingPage />} />
      </Route>
      <Route element={<SubLayout />} errorElement={<ErrorPage />}>
        <Route path="waitings" element={<WaitingsPage />} />
        <Route path="collects" element={<CollectedPage />} />
        <Route path="deliveries" element={<DeliveredPage />} />
        <Route path="cancellations" element={<CancelledPage />} />
        <Route path="order/:orderId" element={<OrderDetailPage />} />
        <Route path="requests" element={<RequestsPage />} />
        <Route path="requests/new" element={<NewRequestPage />} />
        <Route path="point/:userId" element={<PointHistoryPage />} />
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route path="canpass/callback" element={<LoginCallbackPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

export default function App() {
  return <RouterProvider router={router} />;
}
