import { Box, Button, Link } from '@mui/material';

import { useRouteError } from 'react-router-dom';

interface IError {
  statusText: string;
  message: string;
}

export default function ErrorPage() {
  const error = useRouteError() as IError;
  console.error(error);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        m: 10,
      }}
      id="error-page"
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
      <Button>
        <Link href="/">Back to home</Link>
      </Button>
    </Box>
  );
}
