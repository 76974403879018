import { Grid } from '@mui/material';
import OrderSummaryCard from '@/components/OrderSummaryCard';
import { iOrderResponse } from '@/types/types.definition';
import { useNavigate } from 'react-router-dom';

type Props = {
  checkedItems?: string[];
  items: iOrderResponse[];
  isEdit?: boolean;
  onCheckedItem?(orderId: string): void;
  disabledSelected?: boolean;
};
function OrderSummaryCardColumn({ items, onCheckedItem, isEdit, checkedItems, disabledSelected }: Props) {
  const navigate = useNavigate();

  const handleGoToDetail = (id: string) => () => {
    navigate(`/order/${id}`);
  };

  return (
    <Grid container direction="row" justifyContent="start" rowSpacing={4} columnSpacing={3}>
      {items.map((item) => (
        <Grid item key={item.id} sx={{ width: ['100%', '100%', 'auto'] }}>
          <OrderSummaryCard
            disabledSelected={disabledSelected}
            checkedItems={checkedItems}
            item={item}
            isEdit={isEdit}
            onCheckedItem={onCheckedItem}
            onGoToDetail={handleGoToDetail(item.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default OrderSummaryCardColumn;
