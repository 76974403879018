import { IconButton } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';

type Props = {
  onClick: () => void;
};

function EditIconButton({ onClick }: Props) {
  return (
    <IconButton onClick={onClick}>
      <ModeEditTwoToneIcon color="primary" />
    </IconButton>
  );
}

export default EditIconButton;
