import { Avatar, Box, Card, CardContent, Grid, Link, Typography, styled, useTheme } from '@mui/material';

import AgricultureTwoToneIcon from '@mui/icons-material/AgricultureTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { ORDER_STATS } from '@/data/constant';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import { iStatResponse } from '@/types/types.definition';

const AvatarWrapperError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color:  ${theme.colors.error.main};
`
);

const AvatarWrapperSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color:  ${theme.colors.success.main};
`
);

const AvatarWrapperWarning = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.warning.lighter};
      color:  ${theme.colors.warning.main};
`
);

const AvatarWrapperCancelled = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.gradients.black2};
      color:  ${theme.palette.common.white};
`
);

type Props = {
  type: keyof typeof ORDER_STATS;
  count: number;
  href: string;
  data?: iStatResponse | void;
};

const getType = (type: Props['type'], count: Props['count']) => {
  switch (type) {
    case 'WAITING':
      return (
        <>
          <AvatarWrapperError>
            <AutoAwesomeTwoToneIcon fontSize="small" />
          </AvatarWrapperError>
          <Typography variant="h5" mt={1}>
            New Orders
          </Typography>
          <Typography variant="h3" color="red" mt={2}>
            {count}
          </Typography>
        </>
      );
    case 'DELIVERING':
      return (
        <>
          <AvatarWrapperSuccess>
            <ShoppingCartTwoToneIcon />
          </AvatarWrapperSuccess>
          <Typography variant="h5" mt={[0, 1]}>
            Orders Collected
          </Typography>
          <Box mt={[0, 2]}>
            <Typography variant="h3" color="teal">
              {count}
            </Typography>
          </Box>
        </>
      );
    case 'DELIVERED':
      return (
        <>
          <AvatarWrapperWarning>
            <AgricultureTwoToneIcon />
          </AvatarWrapperWarning>
          <Typography variant="h5" mt={[0, 1]}>
            Orders Delivered
          </Typography>
          <Box mt={[0, 2]}>
            <Typography variant="h3" color="darkorange">
              {count}
            </Typography>
          </Box>
        </>
      );

    default:
      return (
        <>
          <AvatarWrapperCancelled>
            <RemoveCircleTwoToneIcon />
          </AvatarWrapperCancelled>
          <Typography variant="h5" mt={[0, 1]}>
            Cancelled Orders
          </Typography>
          <Box mt={[0, 2]}>
            <Typography variant="h3" color="grey">
              {count}
            </Typography>
          </Box>
        </>
      );
  }
};

function OrderTrackingCard({ type, count, href, data }: Props) {
  const theme = useTheme();

  return (
    <Grid xs={12} item>
      <Link href={href} underline="none">
        <Card
          variant="outlined"
          sx={
            type === 'WAITING' && !!data?.WAITING
              ? {
                  backgroundColor: theme.colors.error.lighter,
                  borderColor: theme.colors.error.dark,
                }
              : {}
          }
        >
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {getType(type, type === 'WAITING' && data ? data.WAITING + data.ACCEPTED + data.REQUESTED : count)}
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

export default OrderTrackingCard;
