import { PLUGIN_TOKEN, SELLER_ID } from '@/data/constant';

export const getHeader = (token: string, data: { [name: string]: unknown } = {}) => {
  return {
    SellerId: SELLER_ID,
    UserToken: `Bearer ${token}`,
    PluginToken: `Bearer ${PLUGIN_TOKEN}`,
    ...data,
  };
};
