import { Button, Card, Divider, TextField, Typography } from '@mui/material';
import { Control, Controller, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';

import { Box } from '@mui/system';
import { IFormCustomerInfoInput } from '../';
import PatternPhoneInput from '@/components/PatternFormatInput';

type Props = {
  control: Control<IFormCustomerInfoInput>;
  onHandleSubmit: UseFormHandleSubmit<IFormCustomerInfoInput>;
  onSetData(data: IFormCustomerInfoInput): void;
};

export default function CustomerInfoForm({ control, onHandleSubmit, onSetData }: Props) {
  const onSubmit: SubmitHandler<IFormCustomerInfoInput> = (data) => {
    console.log(data);
    onSetData(data);
  };

  return (
    <Card
      sx={{
        width: '100%',
        p: 3,
      }}
    >
      <Typography variant="h3" mb={3}>
        Customer Details
      </Typography>
      <Box display="flex" flexDirection="column" gap={3}>
        <Controller
          rules={{
            required: 'Name is required',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField label="Customer's Full Name" error={!!error?.message} helperText={error?.message} {...field} />
          )}
          name="buyerName"
          control={control}
          defaultValue=""
        />
        <Controller
          rules={{
            required: 'Mobile number is required',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label="Customer's Mobile Number"
              id="formatted-numberformat-input"
              {...field}
              error={!!error?.message}
              helperText={error?.message}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: PatternPhoneInput as any,
              }}
            />
          )}
          name="buyerPhone"
          control={control}
        />
      </Box>
      <Divider sx={{ mt: 5, mb: 2 }} />
      <Box display="flex" flexDirection="column" mb={10}>
        <Typography variant="h5" mb={2}>
          Memo
        </Typography>
        <Controller
          render={({ field }) => <TextField label="Order details(further require in delivery)" multiline {...field} />}
          name="memo"
          control={control}
          defaultValue=""
        />
      </Box>
      <Button variant="outlined" fullWidth onClick={onHandleSubmit(onSubmit)}>
        Next
      </Button>
    </Card>
  );
}
