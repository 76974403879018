import { Card, Checkbox, Divider, Typography } from '@mui/material';

import { Box } from '@mui/system';
import { SyntheticEvent } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { iOrderResponse } from '@/types/types.definition';
import { useOrderStore } from '@/context/orderStore';

type Props = {
  item: iOrderResponse;
  isEdit?: boolean;
  checkedItems?: string[];
  fullWidth?: boolean;
  onCheckedItem?(orderId: string): void;
  onGoToDetail?(): void;
  disabledSelected?: boolean;
};

const findChecked = (checkValue: string, checkedList: string[] = []): boolean => {
  if (!checkValue) return false;
  return checkedList.findIndex((value) => value === checkValue) !== -1;
};

function OrderSummaryCard({
  onCheckedItem,
  onGoToDetail,
  checkedItems,
  disabledSelected,
  isEdit,
  item,
  fullWidth,
}: Props) {
  const { setSelectedOrder } = useOrderStore();

  const handleCheckBox = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onCheckedItem?.(item?.id);
    if (checkedItems && checkedItems?.length <= 1) {
      setSelectedOrder(item);
    }
  };

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isEdit) {
      handleCheckBox(e);
    } else {
      onGoToDetail?.();
    }
  };

  if (!item) {
    return null;
  }
  return (
    <Card
      sx={{
        width: fullWidth ? '100%' : ['100%', '100%', '16rem'],
        overflow: 'visible',
        cursor: 'pointer',
        border: isEdit && findChecked(item.id, checkedItems) ? '2px solid red' : undefined,
        background: isEdit && findChecked(item.id, checkedItems) ? 'rgba(239, 47, 118, 0.217)' : undefined,
      }}
      onClick={handleClick}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          p: 1,
          pl: 1,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            p: 1,
            pl: 1,
            pb: 1,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
            <Typography variant="h4">{item?.number}</Typography>
            {!isEdit && !disabledSelected && (
              <Checkbox checked={findChecked(item.id, checkedItems)} value={item.id} onClick={handleCheckBox} />
            )}
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                pr: 3,
              }}
            >
              {item.recipient?.name}
            </Typography>
            <Typography variant="h5" color="subtitle1">
              {item.recipient?.phone}
            </Typography>
          </Box>
          {!!item.totalPrice && (
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography
                variant="subtitle1"
                noWrap
                sx={{
                  pr: 1,
                }}
              >
                Total:
              </Typography>
              <Typography variant="h6" color="subtitle1" mr={1}>
                {item.currencyCode}
              </Typography>
              <Typography variant="h5" color="subtitle1">
                {item.totalPrice}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              pr: 1,
            }}
          >
            • {item.Pandago ? item.Pandago.description : 'Looking for rider'}
          </Typography>
          <Typography color="tomato" variant="h5">
            {formatDistanceToNow(item.Pandago ? new Date(item.Pandago.updatedAt) : new Date(item.createdAt))} ago
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default OrderSummaryCard;
