import { AlertType, eDeliveryStatus } from '@/types/enums';
import { Box, Typography } from '@mui/material';
import { OrderEditButton, OrderList } from '@/features/Orders';
import { useDeleteOrder, useGetOrders } from '@/features/Orders/hooks';

import { DeleteAndCancelButtons } from '@/components/buttons';
import React from 'react';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useAlertStore } from '@/context/alertStore';

function CancelledPage() {
  const [isEdit, setEdit] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState<string[]>([]);
  const { setOpen } = useAlertStore();
  const { data, isLoading, refetch } = useGetOrders(eDeliveryStatus.CANCELED);
  const { mutate, isSuccess, isError, error, isLoading: isDeleteLoading, data: deleteData } = useDeleteOrder();

  React.useEffect(() => {
    if (isError || isSuccess) {
      setOpen(
        true,
        isError ? AlertType.ERROR : AlertType.SUCCESS,
        isError ? `${error ?? 'Something wrong try again!'}` : `selected ${deleteData?.count ?? ''} orders  are deleted`
      );
      if (isSuccess) {
        handleCancelDelete();
        refetch();
      }
    }
  }, [isError, isSuccess]);

  const handleCheckedItem = (orderId: string) => {
    if (isEdit) {
      const hasCheckedItem = checkedItems.indexOf(orderId) !== -1;
      const updatedList = hasCheckedItem ? checkedItems.filter((id) => id !== orderId) : [...checkedItems, orderId];
      setCheckedItems(updatedList);
      return;
    }

    setCheckedItems(checkedItems?.[0] === orderId ? [] : [orderId]);
  };

  const handleDeleteOrders = () => {
    mutate(checkedItems);
  };

  const handleChangeEdit = () => {
    setEdit(!isEdit);
  };

  const handleCancelDelete = () => {
    setEdit(false);
    setCheckedItems([]);
  };

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Box mb={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h3">Cancelled Orders {!!data?.length && `(${data.length})`}</Typography>

          <OrderEditButton showButton={!!data?.length} onChangeEdit={handleChangeEdit} />
        </Box>
        {isEdit && <Typography>You can delete orders manually in this page</Typography>}
      </Box>
      <Box display="flex" justifyContent="center" flex="1">
        {isLoading || isDeleteLoading ? (
          <SuspenseLoader />
        ) : (
          <OrderList
            isEdit={isEdit}
            items={data ?? []}
            disabledSelected
            checkedItems={checkedItems}
            onCheckedItem={handleCheckedItem}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            bottom: '7vh',
            width: ['90%', '50%'],
          }}
        >
          {isEdit && (
            <DeleteAndCancelButtons
              onCancelDelete={handleCancelDelete}
              onDelete={handleDeleteOrders}
              itemLength={checkedItems.length}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default CancelledPage;
