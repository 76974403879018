import { Avatar, Box, Divider, Stack } from '@mui/material';

import { EditIconButton } from '@/components/buttons';
import { RequestProps } from '../';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import Typography from '@mui/material/Typography';

type Props = Omit<RequestProps, 'dropOffInfo'>;

export default function CollectPointSummary({ showEditButton, buyerInfo, onStepChange }: Props) {
  return (
    <Box display="flex" alignItems="flex-start" sx={{ m: 2, height: '50%' }}>
      <Stack direction="column" sx={{ height: '100%' }}>
        <Avatar sx={{ bgcolor: 'teal' }}>
          <ShoppingCartTwoToneIcon />
        </Avatar>
        <Box display="flex" sx={{ flex: 1, justifyContent: 'center' }}>
          <Divider component="div" orientation="vertical" flexItem sx={{ backgroundColor: 'ActiveBorder', mt: 2 }} />
        </Box>
      </Stack>
      <Stack flex={1} ml={2}>
        <Stack direction="row" gap={2}>
          <Typography mt={1} variant="h4">
            Collection Point
          </Typography>
          {showEditButton && <EditIconButton onClick={() => onStepChange(0)} />}
        </Stack>
        <Divider />
        <Stack p={1} gap={2}>
          <Typography sx={{ display: 'inline' }} component="span" variant="subtitle2" color="primary">
            {buyerInfo.orderId}
          </Typography>
          <Box>
            <Typography variant="h4" color="text.primary">
              {buyerInfo.buyerName}
            </Typography>
            <Typography variant="h6" color="text.primary">
              {buyerInfo.buyerPhone}
            </Typography>
          </Box>
          {buyerInfo.memo && (
            <>
              <Typography variant="h5">MEMO:</Typography>
              <Typography variant="h6" color="text.primary">
                {buyerInfo.memo}
              </Typography>
              <Divider />
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
