import { Button } from '@mui/material';
import ConfirmModal from '@/components/Modals/ConfirmModal';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useState } from 'react';

type Props = {
  onClick(): void;
};
function Logout({ onClick }: Props): React.ReactElement {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    onClick();
  };
  return (
    <>
      <Button color="primary" onClick={handleClickOpen}>
        <LockOpenTwoToneIcon sx={{ mr: 1 }} />
        Log out
      </Button>
      <ConfirmModal
        open={open}
        onClose={handleClickClose}
        onConfirm={handleConfirm}
        title="Confirm Logout"
        content="Do you want to logout?"
        confirmText="Logout"
      />
    </>
  );
}

export default Logout;
