import { Box, Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material';

import Brightness1Icon from '@mui/icons-material/Brightness1';
import { dateFormat } from '@/utils/date';
import { useGetUserPointHistory } from './hooks';
import { useParams } from 'react-router-dom';

const PointHistory = () => {
  const params = useParams();
  const { data } = useGetUserPointHistory(params?.userId);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Typography variant="body1">Save up your points to get a premium rewards, or use it like a cash</Typography>
      {data?.length === 0 ? (
        <Box display="flex" flex="1" justifyContent="center" alignItems="center">
          <Typography variant="h3">History is empty</Typography>
        </Box>
      ) : (
        <Stack spacing={2} p={3} flex="1" sx={{ overflowY: 'auto' }}>
          {data?.map((item) => (
            <Card key={item.id}>
              <CardContent>
                <Typography>{dateFormat(item.createdAt)}</Typography>
                <Stack direction="row" spacing={3} mt={1}>
                  <Typography variant="h6" color="GrayText">
                    {item.User.name}
                  </Typography>
                  <Typography variant="h5">{item.User.phone}</Typography>
                </Stack>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                  <Box>
                    <Brightness1Icon sx={{ fontSize: '0.6rem', mr: 1, color: 'purple' }} />
                    <Typography component="span" variant="h5" color="purple">
                      Save Point
                    </Typography>
                  </Box>
                  <Typography variant="h4">{item.value}</Typography>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      )}

      <Button LinkComponent="a" href="/point" role="link" variant="outlined" color="primary">
        + Next
      </Button>
    </Box>
  );
};

export default PointHistory;
