import { Button, Typography } from '@mui/material';

import { Box } from '@mui/system';

const NUMBER_PAD_CONTENT = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'CLEAR', '0', 'OK'];

type Props = {
  onClick(number: string): void;
};

function NumberPad({ onClick }: Props) {
  return (
    <Box
      sx={{
        display: 'grid',
        width: 1,
        gap: '0.5rem 0.5rem',
        gridTemplateRows: 'repeat(4, 60px)',
        gridTemplateColumns: 'repeat(3, 1fr)',
      }}
    >
      {NUMBER_PAD_CONTENT.map((number) => (
        <Button variant="contained" color="info" key={number} value={number} onClick={() => onClick(number)}>
          <Typography variant="h3">{number}</Typography>
        </Button>
      ))}
    </Box>
  );
}

export default NumberPad;
