import { Box, Card, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { iUserResponse } from '@/types/types.definition';

type Props = {
  onClose: () => void;
  onClickItem: (selectedUser: iUserResponse) => void;
  users: iUserResponse[];
};

const UserCardList = ({ onClose, onClickItem, users }: Props) => {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState<iUserResponse | null>(null);
  useEffect(() => {
    if (selectedItem) {
      const timer = setTimeout(() => {
        onClickItem(selectedItem);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [selectedItem]);

  const handleClick = (item: iUserResponse) => setSelectedItem(item);

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <List
        sx={{
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {users.map((data) => (
          <ListItem
            key={data.id}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Card
              sx={{
                cursor: 'pointer',
                p: 2,
                width: '100%',
                backgroundColor:
                  selectedItem?.id === data.id ? theme.colors.primary.light : theme.colors.primary.lighter,
              }}
              onClick={() => handleClick(data)}
            >
              <Stack spacing={2}>
                <Typography variant="h3">
                  <Typography>Name:</Typography> {data.name}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Typography variant="body1">
                    <Typography component="span">ID:</Typography> {data.id}
                  </Typography>
                  <Typography variant="h5">
                    <Typography component="span">Phone:</Typography> {data.phone}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UserCardList;
