import { AlertType } from '@/types/enums';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface AlertStore {
  open: boolean;
  text: string;
  type: AlertType;
  setOpen: (open: boolean, type?: AlertType, text?: string) => void;
}

export const useAlertStore = create<AlertStore>()(
  devtools((set) => ({
    open: false,
    text: '',
    type: AlertType.SUCCESS,
    setOpen: (open, type, text) => {
      if (!open) {
        set({ open: false, text: '' });
        return;
      }
      set({ open, type, text });
    },
  }))
);
