import { Container, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

import BaseLayout from './BaseLayout';
import Copyright from './components/Copyright';
import { Outlet } from 'react-router-dom';
import RequireAuth from '@/features/Authentication/components/RequireAuth';
import SubHeader from './components/SubHeader';

interface SubLayoutProps {
  children?: ReactNode;
}

const SubLayout: FC<SubLayoutProps> = () => {
  const theme = useTheme();

  return (
    <RequireAuth>
      <BaseLayout>
        <>
          <SubHeader />
          <Container
            sx={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              mt: theme.header.height,
              p: [2, 3],
              pt: 2,
            }}
          >
            <Outlet />
          </Container>
          <Copyright />
        </>
      </BaseLayout>
    </RequireAuth>
  );
};

export default SubLayout;
