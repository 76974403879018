import React from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { themeCreator } from './base';

type ThemeProps = {
  children: React.ReactNode;
};
const ThemeProviderWrapper: React.FC<ThemeProps> = ({ children }) => {
  const theme = themeCreator('PureLightTheme');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
