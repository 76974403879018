import { Button } from '@mui/material';
import CancelRiderModal from '@/components/Modals/CancelRiderModal';
import { useState } from 'react';

type Props = {
  onCancelRider(value: string): void;
};
function CancelRiderButton({ onCancelRider }: Props) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleConfirm = (value: string) => {
    setOpen(false);
    onCancelRider(value);
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        Cancel Rider
      </Button>
      <CancelRiderModal open={open} title="Cancel rider" onClose={handleClickClose} onClick={handleConfirm} />
    </>
  );
}

export default CancelRiderButton;
