import { getPointHistory, getSearchById, getSearchByPhone, putUserPoint } from '../services';
import { useMutation, useQuery } from '@tanstack/react-query';

import { iUserResponse } from '@/types/types.definition';
import { useLoginStore } from '@/context/authStore';
import { useMemo } from 'react';
import { usePointStore } from '@/context/pointStore';

export const useGetUserInfoByPhoneMemo = (mobile?: string) => {
  const { userToken } = useLoginStore();
  const { setUsers } = usePointStore();

  const { isError, data, error, refetch, isLoading, isFetching } = useQuery(
    ['userInfo', mobile],
    () => getSearchByPhone(mobile!, userToken?.access_token),
    {
      onSuccess: (data) => {
        if (data && mobile) {
          setUsers(mobile, data);
        }
      },
      enabled: false,
    }
  );

  return useMemo(() => {
    return { isError, data, error, refetch, isLoading, isFetching };
  }, [isError, data, error, refetch, isLoading, isFetching]);
};

export const useGetUserInfoByIDMemo = () => {
  const { userToken } = useLoginStore();
  const { selectedUser } = usePointStore();

  const { isError, data, error, refetch, isLoading, isFetching } = useQuery(['userInfo', selectedUser?.id], () =>
    getSearchById(selectedUser?.id, userToken?.access_token)
  );

  return useMemo(() => {
    return { isError, data, error, refetch, isLoading, isFetching };
  }, [isError, data, error, refetch, isLoading, isFetching]);
};

export const useGetUserPointHistory = (id?: string) => {
  const { userToken } = useLoginStore();

  const { isError, data, error, refetch, isLoading, isFetching } = useQuery(['userInfo', id], () =>
    getPointHistory(id, userToken?.access_token)
  );

  return useMemo(() => {
    return { isError, data, error, refetch, isLoading, isFetching };
  }, [isError, data, error, refetch, isLoading, isFetching]);
};

type Props = {
  onSuccess: (data?: iUserResponse) => void;
};

export const useSaveUserPoint = ({ onSuccess }: Props) => {
  const { userToken } = useLoginStore();
  const { selectedUser, setSavePointData } = usePointStore();

  const {
    isError,
    data,
    error,
    mutate: saveUserPoint,
    isLoading,
  } = useMutation((credit: number) => putUserPoint(userToken?.access_token, selectedUser?.id, credit), {
    onSuccess: (data: iUserResponse) => {
      setSavePointData(data);
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  return { isError, data, error, saveUserPoint, isLoading };
};
