import { Card, Grid } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { ORDER_STATS } from '@/data/constant';
import OrderTrackingCard from './components/OrderTrackingCard';
import OrderTrackingTitle from './components/OrderTrackingTitle';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useGetOrderStat } from './hooks';

type StatsKeys = keyof typeof ORDER_STATS;
const status = Object.keys(ORDER_STATS) as StatsKeys[];

const OrderTracking = () => {
  const { data, isLoading, refetch, fetchStatus } = useGetOrderStat();
  return (
    <Grid container item direction="row" justifyContent="center" md>
      <Grid container item direction="column" xs={12}>
        <Grid item sx={{ mb: 3 }}>
          <OrderTrackingTitle />
        </Grid>
        <Grid item container spacing={[1, 3]} sx={{ position: 'relative' }}>
          {isLoading && <SuspenseLoader />}
          <Grid item container spacing={1} xs={9} sm={9} md={10}>
            {status.map((status: StatsKeys) => (
              <OrderTrackingCard
                key={status}
                type={status}
                data={data}
                count={data?.[status] ?? 0}
                href={ORDER_STATS[status]}
              />
            ))}
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
                padding: [0, 2],
              }}
              onClick={() => refetch()}
            >
              <LoadingButton
                loading={fetchStatus === 'fetching'}
                loadingIndicator="Loading…"
                loadingPosition={fetchStatus === 'fetching' ? undefined : 'start'}
                startIcon={fetchStatus !== 'fetching' && <RefreshTwoToneIcon />}
                sx={{
                  display: ['flex', 'inline-flex'],
                  flexDirection: ['column', 'row'],
                }}
              >
                {fetchStatus !== 'fetching' && 'Refresh'}
              </LoadingButton>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderTracking;
