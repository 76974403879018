import { NumericFormatProps, PatternFormat } from 'react-number-format';

import React from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PatternPhoneInput = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      format="+65 #### ####"
      allowEmptyFormatting
      mask="_"
    />
  );
});

export default PatternPhoneInput;
