import { useNavigate, useParams } from 'react-router-dom';

import { AlertType } from '@/types/enums';
import { OrderDetail } from '@/features/Orders';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useAlertStore } from '@/context/alertStore';
import { useEffect } from 'react';
import { usePostOnlineRequestRider } from '@/features/Request';

function OrderDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { onlineRequestRider, isLoading, isSuccess, isError, error } = usePostOnlineRequestRider();

  const { setOpen } = useAlertStore();

  useEffect(() => {
    if (isError) {
      setOpen(true, AlertType.ERROR, `${error ?? 'Something wrong try again!'}`);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setOpen(true, AlertType.SUCCESS, `Request rider is success!`);
      navigate('/tracking');
    }
  }, [isSuccess]);

  return (
    <>
      {isLoading && <SuspenseLoader />}
      <OrderDetail orderId={params.orderId} onRequestRider={onlineRequestRider} />
    </>
  );
}

export default OrderDetailPage;
