import { Box, Typography } from '@mui/material';

import { OrderList } from '@/features/Orders';
import SuspenseLoader from '@/components/SuspenseLoader';
import { eDeliveryStatus } from '@/types/enums';
import { useGetOrders } from '@/features/Orders/hooks';

function CollectedPage() {
  const { data, isLoading } = useGetOrders(eDeliveryStatus.DELIVERING);

  return (
    <Box display="flex" flexDirection="column" flex="1">
      <Box display="flex" mb={3} flexDirection="row" justifyContent="space-between">
        <Typography variant="h3">Order collected {!!data?.length && `(${data.length})`}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" flex="1">
        {isLoading ? <SuspenseLoader /> : <OrderList items={data ?? []} disabledSelected />}
      </Box>
    </Box>
  );
}

export default CollectedPage;
