import { Button, Dialog, DialogContent, DialogTitle, Divider, InputAdornment, Slide, TextField } from '@mui/material';
import React, { ChangeEvent, ReactElement, Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { useGetOrdersSearch, useGetSearchKeywords } from './hooks/index';

import SearchResult from './components/SearchResult';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SearchedKeywords from './components/SearchedKeywords';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';
import useDebounce from '@/hooks/useDebounce';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

// type Props = {
//   onClick(event: React.ChangeEvent<HTMLInputElement>): void;
// };

function HeaderSearch(): React.ReactElement {
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const debouncedFilter = useDebounce(searchValue, 500);
  const { data, isFetched } = useGetOrdersSearch(debouncedFilter);
  const { keywords } = useGetSearchKeywords();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (open) {
      const searchInput = inputRef?.current as unknown as HTMLInputElement;
      searchInput.focus();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchValue('');
  };

  const handleSetSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <>
      <Button variant="contained" color="info" onClick={handleClickOpen}>
        <SearchTwoToneIcon sx={{ mr: 1 }} />
        Search
      </Button>

      <DialogWrapper
        open={open}
        keepMounted
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            value={searchValue}
            inputRef={inputRef}
            autoFocus
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search terms here..."
            fullWidth
            label="Search"
          />
        </DialogTitleWrapper>
        <Divider />
        <DialogContent>
          {isFetched ? (
            <SearchResult items={data ?? []} searchValue={searchValue} />
          ) : (
            <SearchedKeywords items={keywords ?? []} onSetSearchValue={handleSetSearchValue} />
          )}
        </DialogContent>
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
