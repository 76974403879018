import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose(): void;
  onConfirm(values?: unknown): void;
  title: string;
  content: string;
  confirmText?: string;
  cancelText?: string;
};

function ConfirmModal({ open, onClose, onConfirm, title, content, confirmText, cancelText }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ minWidth: '15.5rem' }}>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
        <Button color="secondary" onClick={onClose}>
          {cancelText ?? 'Cancel'}
        </Button>
        <Button onClick={onConfirm} autoFocus>
          {confirmText ?? 'Agree'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
