import { Box, Typography } from '@mui/material';

import OrderSummaryCardColumn from './OrderSummaryCardColumn';
import ProductionQuantityLimitsTwoToneIcon from '@mui/icons-material/ProductionQuantityLimitsTwoTone';
import { iOrderResponse } from '@/types/types.definition';
import { useMemo } from 'react';

type Props = {
  checkedItems?: string[];
  onCheckedItem?(orderId: string): void;
  isEdit?: boolean;
  items?: iOrderResponse[];
  limit?: number;
  disabledSelected?: boolean;
};

function OrderList({ items, onCheckedItem, isEdit, checkedItems, limit, disabledSelected }: Props) {
  const list = useMemo(() => (limit ? items?.slice(0, limit) : items), [items, limit]);
  const hasEmpty = useMemo(() => items?.length === 0, [items]);
  return (
    <>
      {hasEmpty ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%', textAlign: 'center' }}
        >
          <ProductionQuantityLimitsTwoToneIcon color="disabled" />
          <Typography
            align="center"
            variant="h3"
            color="text.secondary"
            sx={{
              mt: 3,
            }}
            gutterBottom
          >
            Empty Orders
          </Typography>
        </Box>
      ) : (
        <OrderSummaryCardColumn
          disabledSelected={disabledSelected}
          items={list ?? []}
          onCheckedItem={onCheckedItem}
          isEdit={isEdit}
          checkedItems={checkedItems}
        />
      )}
    </>
  );
}

export default OrderList;
