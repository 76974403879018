/**
 * @description 배달상태를 나타내는 enum 타입
 */
export enum eDeliveryStatus {
  WAITING = 'WAITING',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum ePoint {
  EARN = 'EARN', // 적립
  SPEND = 'SPEND', // 차감
}

export enum AlertType {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
