import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IconButton } from '@mui/material';

type Props = {
  onChangeEdit(): void;
};

function OrderDeleteButton({ onChangeEdit }: Props) {
  return (
    <IconButton onClick={onChangeEdit}>
      <DeleteTwoToneIcon color="error" />
    </IconButton>
  );
}

export default OrderDeleteButton;
