import { Avatar, Box, Divider, Stack } from '@mui/material';

import AgricultureTwoToneIcon from '@mui/icons-material/AgricultureTwoTone';
import EditIconButton from '@/components/buttons/EditIconButton';
import { RequestProps } from '../';
import Typography from '@mui/material/Typography';

type Props = Omit<RequestProps, 'buyerInfo'>;
export default function DropOffSummary({ showEditButton, dropOffInfo, onStepChange }: Props) {
  return (
    <Box display="flex" alignItems="flex-start" sx={{ m: 2, mt: 0 }}>
      <Stack direction="column" sx={{ height: '100%' }}>
        <Avatar sx={{ bgcolor: 'teal' }}>
          <AgricultureTwoToneIcon />
        </Avatar>
      </Stack>
      <Stack flex={1} ml={2}>
        <Stack direction="row" gap={2}>
          <Typography mt={1} variant="h4">
            Customer Drop off
          </Typography>
          {showEditButton && <EditIconButton onClick={() => onStepChange(1)} />}
        </Stack>
        <Divider />
        <Stack p={1}>
          <Typography variant="h6" color="text.primary">
            {dropOffInfo?.recipientName ?? ''}
            {dropOffInfo.recipientPhone ? `(${dropOffInfo.recipientPhone})` : ''}
          </Typography>
          <Typography variant="h6" color="text.primary">
            <address>{dropOffInfo.zipCode}</address>
            <address>{dropOffInfo.address}</address>
            <address>{dropOffInfo.unit}</address>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
