import { iOrderResponse, iPandagoRiderRequest, iSenderInfo } from '@/types/types.definition';

import { BASE_API_URL } from '@/data/constant';
import { OnlineOrderRequest } from '../types/request';
import axios from 'axios';
import { getHeader } from '@/services';

export const getAddressByPostCode = (value: string) => {
  const url = ' https://developers.onemap.sg/commonapi/search?searchVal=';
  return axios.get(`${url}${value}&returnGeom=Y&getAddrDetails=Y`).then((result) => result);
};

export const getSellerInfo = (token?: string) => {
  if (!token) {
    throw new Error("token doesn't exist");
  }

  const url = `${BASE_API_URL}/sellers` as string;

  return axios
    .get(url, {
      headers: getHeader(token),
    })
    .then((result): Promise<iSenderInfo> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};

export const postOfflineRequestRider = (token: string | undefined, data: iPandagoRiderRequest) => {
  if (!token) {
    throw new Error("token doesn't exist");
  }
  const url = `${BASE_API_URL}/orders` as string;
  return axios.post<iOrderResponse>(url, data, {
    headers: getHeader(token),
  });
};

export const putOnlineRequestRider = (data: Partial<OnlineOrderRequest>, token?: string, orderId?: string) => {
  if (!token || !orderId || !data) {
    throw new Error('token or orderId does not exist');
  }

  const url = `${BASE_API_URL}/orders/${orderId}` as string;

  return axios.put(
    url,
    {
      Pandago: data.Pandago ?? undefined,
      deliveryStatus: data.deliveryStatus ?? undefined,
    },
    {
      headers: getHeader(token),
    }
  );
};
