import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AlertType, eDeliveryStatus } from '@/types/enums';
import { OnlineOrderRequest, usePostOnlineRequestRider } from '@/features/Request';

import Brightness1Icon from '@mui/icons-material/Brightness1';
import { CancelRiderButton } from '@/components/buttons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import SuspenseLoader from '@/components/SuspenseLoader';
import { checkDeleteStatus } from '@/utils/common';
import { dateFormat } from '@/utils/date';
import { iPayment } from '@/types/types.definition';
import { useAlertStore } from '@/context/alertStore';
import { useGetOrderDetail } from './hooks';

type AccordionType = {
  order: boolean;
  customer: boolean;
};

type Props = {
  orderId?: string;
  onRequestRider: (data: OnlineOrderRequest) => void;
};

function OrderDetail({ orderId, onRequestRider }: Props) {
  const theme = useTheme();
  const { setOpen } = useAlertStore();
  const { data, isLoading, refetch } = useGetOrderDetail(orderId);
  const {
    onlineRequestRider,
    isLoading: isOnlineRequestLoading,
    isSuccess,
    isError,
    error,
  } = usePostOnlineRequestRider();
  const payment: iPayment | null | undefined = data?.payment;

  const [expanded, setExpanded] = React.useState<AccordionType>({
    order: true,
    customer: true,
  });
  const [isCancel, setIsCancel] = React.useState(false);

  React.useEffect(() => {
    if (isError || isSuccess) {
      setOpen(
        true,
        isError ? AlertType.ERROR : AlertType.SUCCESS,
        isError
          ? `${error ?? 'Something wrong try again!'}`
          : isCancel
          ? `It has been successfully canceled.`
          : `A rider request has been successfully made.`
      );
      if (isSuccess) {
        refetch();
      }
      setIsCancel(false);
    }
  }, [isError, isSuccess]);

  const handleAccordionChange = (name: keyof AccordionType) => (_event: React.SyntheticEvent, isExpanded: boolean) =>
    setExpanded((state) => ({ ...state, [name]: isExpanded }));

  const handleCancelRider = (value: string) => {
    if (!data || !data.recipient) return;
    console.log('cancel:', value);
    setIsCancel(true);
    const requestData = {
      id: data.id,
      deliveryStatus: eDeliveryStatus.CANCELED,
    };
    onlineRequestRider(requestData);
  };

  const handleRequestRider = () => {
    if (!data) return;
    setIsCancel(false);
    onRequestRider({
      id: data.id,
      Pandago: { sender: data.Seller!, recipient: data.recipient! },
    });
  };

  if (isLoading || !data) return <SuspenseLoader />;
  return (
    <Box sx={{ width: '100%' }}>
      {isOnlineRequestLoading && <SuspenseLoader />}
      <Card
        sx={{
          width: '100%',
        }}
      >
        <CardHeader
          title={
            <Box>
              <Typography variant="h6" color="GrayText">
                ORDER ID
              </Typography>
              <Typography variant="h3"> {data.number}</Typography>
              <Typography variant="h6" color="GrayText">
                TOTAL DELIVERY COST
                <Typography
                  variant="h6"
                  component="span"
                  color="black"
                  sx={{
                    ml: 2,
                  }}
                >
                  {data?.deliveryFee} {data?.currencyCode}
                </Typography>
              </Typography>
            </Box>
          }
          subheader={
            <Stack direction="row" alignItems="center" mt={1}>
              <Brightness1Icon sx={{ typography: 'body1', mr: 1 }} />
              {`${data.payment ? 'WEB' : 'OFFLINE'} - ${data.deliveryStatus} ${
                data?.Pandago?.description ? ` (${data?.Pandago?.description})` : ''
              }`}
            </Stack>
          }
          subheaderTypographyProps={{
            variant: 'h6',
            color: data.payment?.platform ? 'red' : 'black',
          }}
          sx={{ borderBottom: 1 }}
        />
        <CardContent>
          <Box textAlign="end">
            <Typography variant="caption">Date</Typography>
            <Typography variant="h6">{dateFormat(Number(data.createdAt))}</Typography>
          </Box>
          {!data.payment && (
            <Accordion expanded={expanded.order} onChange={handleAccordionChange('order')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography variant="h4">Order Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  <Box mb={theme.spacing(2)}>
                    <Typography variant="caption">ITEMS</Typography>
                    {data.purchaseItems?.map((item) => (
                      <Box display="flex" justifyContent="flex-start" key={item.name + orderId}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Typography variant="h6" pl={3}>
                          {item.quantity}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box mb={theme.spacing(2)}>
                    <Typography variant="caption">ORDER TOTAL</Typography>
                    <Typography variant="h6">
                      {data.totalPrice}
                      <span> {data?.currencyCode}</span>
                    </Typography>
                  </Box>
                  <Box mb={theme.spacing(2)}>
                    <Typography variant="caption">PAYMENT METHOD</Typography>
                    <Typography variant="h6">{`${
                      payment ? `${payment.method} (${payment.platform})` : 'OFFLINE'
                    }`}</Typography>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion expanded={expanded.customer} onChange={handleAccordionChange('customer')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography variant="h4">Customer's Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                <Box mb={theme.spacing(2)}>
                  <Typography variant="caption">NAME</Typography>
                  <Typography variant="h6">{data.recipient?.name}</Typography>
                </Box>
                <Box mb={theme.spacing(2)}>
                  <Typography variant="caption">MOBILE NUMBER</Typography>
                  <Typography variant="h6">{data.recipient?.phone}</Typography>
                </Box>
                <Box mb={theme.spacing(2)}>
                  <Typography variant="caption">ADDRESS</Typography>
                  <Typography variant="h6">
                    <address>{data.recipient?.location.postalcode}</address>
                    <address>{data.recipient?.location.address}</address>
                  </Typography>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </CardContent>
        <Stack m={theme.spacing(2)} spacing={2}>
          {data.deliveryStatus === eDeliveryStatus.WAITING && (
            <Button variant="contained" fullWidth onClick={() => orderId && handleRequestRider()}>
              Request Rider
            </Button>
          )}
          {checkDeleteStatus(data.deliveryStatus) && <CancelRiderButton onCancelRider={handleCancelRider} />}
        </Stack>
      </Card>
    </Box>
  );
}

export default OrderDetail;
