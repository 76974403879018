export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const SELLER_ID = process.env.REACT_APP_SELLER_ID;
export const PLUGIN_URL = process.env.REACT_APP_PLUGIN_URL;
export const PLUGIN_TOKEN = process.env.REACT_APP_PLUGIN_TOKEN;
export const CANPASS_AUTH_URL = process.env.REACT_APP_CANPASS_AUTH_URL;

export const ORDER_STATS = {
  WAITING: '/waitings',
  DELIVERING: '/collects',
  DELIVERED: '/deliveries',
  CANCELED: '/cancellations',
};
export const MENU_TITLE = {
  waitings: 'New orders',
  collects: 'Orders collected',
  deliveries: 'Orders delivered',
  cancellations: 'Cancelled orders',
  requests: 'Request Orders',
  ['requests/new']: 'Create New delivery',
  ['requests/rider']: 'Request a rider',
  point: 'Point History',
};

export const TEXT = {
  REQUEST_RIDER: 'REQUEST A RIDER',
  POINT_SERVICE: 'POINT SERVICE',
};

export const POINT_ERROR_TEXT = {
  NO_DATA: 'No matching account associated with this phone number. Please try again.',
  PHONE: 'Phone number is empty',
  POINT: 'Point should be not 0',
};

export const POINT_STEP = ['Phone Number', 'Point', 'Done'];
export const POINT_TYPE = { EARN: 'EARN', SPEND: 'SPEND' };

export const getAuthorizationUrl = () => {
  // const params = {
  //   response_type: 'code',
  //   client_id: 'G0CUWIRCRG',
  //   scope: 'email',
  //   redirect_uri: `https://plugindev.moimplugin.com/canpass/callback`,
  //   action: 'signin'
  // };

  // return `https://vingle.network/api/v2/auth?${queryString.stringify(params)}`;
  return `${CANPASS_AUTH_URL}?client_id=${CLIENT_ID}}&redirect_uri=${PLUGIN_URL}/canpass/callback&state=state`;
};

// const title = {
//   orderListPage: {
//     path:'/orders',
//     title: 'Order List'
//   },
// }
// export const getSubHeaderName
