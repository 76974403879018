import { FC, ReactNode } from 'react';
import { SwipeableDrawer, useTheme } from '@mui/material';

import BaseLayout from './BaseLayout';
import { Container } from '@mui/system';
import Copyright from './components/Copyright';
import Header from './components/Header';
import { Outlet } from 'react-router-dom';
import RequireAuth from '@/features/Authentication/components/RequireAuth';
import UserCardList from './components/UserCardList';
import { iUserResponse } from '@/types/types.definition';
import { usePointStore } from '@/context/pointStore';

interface DefaultLayoutProps {
  children?: ReactNode;
}

const DefaultLayout: FC<DefaultLayoutProps> = () => {
  const theme = useTheme();
  const { users, drawerOpen, setDrawerOpen, setSelectedUser, setActiveStep } = usePointStore();

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleCardItemClick = (selectedUser: iUserResponse) => {
    setSelectedUser(selectedUser);
    setDrawerOpen(false);
    setActiveStep(1);
  };

  return (
    <RequireAuth>
      <BaseLayout>
        <Header />
        <Container
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            p: {
              xs: `${theme.header.height} ${theme.spacing(2)}`,
              md: `${theme.header.height} ${theme.spacing(3)}`,
            },
            mt: 4,
          }}
        >
          <Outlet />
        </Container>
        <Copyright pb={2} />

        <SwipeableDrawer
          variant="temporary"
          ModalProps={{
            keepMounted: false,
          }}
          anchor="bottom"
          open={drawerOpen}
          onClose={handleToggleDrawer(false)}
          onOpen={handleToggleDrawer(true)}
        >
          <UserCardList
            onClose={() => handleToggleDrawer(false)}
            onClickItem={handleCardItemClick}
            users={users ?? []}
          />
        </SwipeableDrawer>
      </BaseLayout>
    </RequireAuth>
  );
};

export default DefaultLayout;
