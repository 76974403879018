import { Box, styled } from '@mui/material';

import { Link } from 'react-router-dom';
import LogoImage from '@/assets/images/market_blue_logo.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 120px;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        img {
          width: 100%;
        }
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <LogoSignWrapper>
        <img src={LogoImage} />
      </LogoSignWrapper>
    </LogoWrapper>
  );
}

export default Logo;
