import { Box, Stack, Typography } from '@mui/material';

import { RequestList } from '@/features/Request';

function RequestsPage() {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack mb={3}>
        <Typography>Enter your customer's address and we can select a ride for you</Typography>
        <Typography variant="h3"> Select a new order</Typography>
      </Stack>
      <RequestList />
    </Box>
  );
}

export default RequestsPage;
