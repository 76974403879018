import { Box, IconButton, Typography, alpha, styled } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MENU_TITLE } from '@/data/constant';
import { useMemo } from 'react';
import { useOrderStore } from '@/context/orderStore';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 100;
        background-color: ${alpha(theme.header.background as string, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        width: 100%;
`
);

function SubHeader() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedOrder } = useOrderStore();
  const params = useParams();
  const handleBackToHome = () => {
    navigate(-1);
  };
  const pathName = useMemo(() => {
    if (params?.userId) {
      return 'point' as keyof typeof MENU_TITLE;
    }
    return pathname.replace('/', '') as keyof typeof MENU_TITLE;
  }, [pathname]);

  return (
    <HeaderWrapper display="flex" alignItems="center" justifyContent="flex-start">
      <Box>
        <IconButton onClick={handleBackToHome}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box sx={{ ml: 2 }}>
        <Typography variant="h4">
          {params?.orderId ? selectedOrder?.number ?? params?.orderId : MENU_TITLE[pathName]}
        </Typography>
      </Box>
    </HeaderWrapper>
  );
}

export default SubHeader;
