import { Navigate, useLocation } from 'react-router-dom';

import { useLoginStore } from '@/context/authStore';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const isLogin = useLoginStore((state) => state.userToken);
  const location = useLocation();

  if (!isLogin && location.pathname !== '/login') {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" replace />;
  }

  return children;
}
