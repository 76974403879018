import { Button, Grid } from '@mui/material';

import OrderSummaryCard from '@/components/OrderSummaryCard';
import React from 'react';
import SuspenseLoader from '@/components/SuspenseLoader';
import { eDeliveryStatus } from '@/types/enums';
import { iOrderResponse } from '@/types/types.definition';
import { useGetOrders } from '@/features/Orders';
import { useNavigate } from 'react-router-dom';

function RequestList() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetOrders(eDeliveryStatus.WAITING);
  const [checkedItems, setCheckedItems] = React.useState<string[]>([]);

  const handleCheckedItem = (orderId: string) => {
    if (checkedItems?.[0] === orderId) {
      return setCheckedItems([]);
    }

    setCheckedItems([orderId]);
  };

  const handleRequestOrder = () => {
    navigate('/requests/new');
  };

  if (isLoading) {
    return <SuspenseLoader />;
  }

  return (
    <Grid container direction="column" justifyContent="center" spacing={3}>
      <Grid item container spacing={3} justifyContent="center">
        {data
          ?.filter((data) => !data.Pandago)
          .map((item: iOrderResponse) => (
            <Grid item key={item.id}>
              <OrderSummaryCard onCheckedItem={handleCheckedItem} checkedItems={checkedItems} item={item} />
            </Grid>
          ))}
      </Grid>

      <Grid item container justifyContent="center">
        <Grid
          item
          sx={{
            display: 'flex',
            position: 'fixed',
            bottom: '5vh',
            width: ['90%', '50%'],
          }}
        >
          <Button
            variant="contained"
            color="info"
            fullWidth
            disabled={checkedItems.length !== 1}
            onClick={handleRequestOrder}
          >
            Request a rider
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RequestList;
