import { Box, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';

import Logout from '../Buttons/Logout';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);
type Props = {
  onLogout(): void;
};

function HeaderMenu({ onLogout }: Props) {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem classes={{ root: 'MuiListItem-indicators' }} component={NavLink} to="/tracking">
            <ListItemText primaryTypographyProps={{ noWrap: true }} primary="Request a Rider" />
          </ListItem>
          <ListItem classes={{ root: 'MuiListItem-indicators' }} component={NavLink} to="/point">
            <ListItemText primaryTypographyProps={{ noWrap: true }} primary="Service of Point" />
          </ListItem>
        </List>
      </ListWrapper>
      <ListWrapper
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem ref={ref} onClick={handleOpen}>
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  <MenuTwoToneIcon />
                </Box>
              }
            />
          </ListItem>
        </List>
      </ListWrapper>
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/tracking">
          Request a rider
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/point">
          Service of Point
        </MenuItem>
        <MenuItem sx={{ px: 3 }}>
          <Logout onClick={onLogout} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default HeaderMenu;
