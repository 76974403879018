import { iHistoryResponse, iUserResponse } from '@/types/types.definition';

import { BASE_API_URL } from '@/data/constant';
import axios from 'axios';
import { getHeader } from '@/services';

export const getSearchByPhone = (phone: string, token?: string) => {
  const url = `${BASE_API_URL}/users?phone=${phone}` as string;

  if (!token) {
    throw new Error('token does not exist');
  }

  return axios
    .get(url, {
      headers: getHeader(token),
    })
    .then((result): Promise<iUserResponse | iUserResponse[]> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};
export const getSearchById = (id?: string, token?: string) => {
  if (!id || !token) {
    return undefined;
  }

  const url = `${BASE_API_URL}/users/${id}` as string;

  return axios
    .get(url, {
      headers: getHeader(token),
    })
    .then((result): Promise<iUserResponse> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};

export const getPointHistory = (id?: string, token?: string) => {
  if (!id || !token) {
    throw new Error('token does not exist');
  }

  const url = `${BASE_API_URL}/histories?UserId=${id}` as string;

  return axios
    .get(url, {
      headers: getHeader(token),
    })
    .then((result): Promise<iHistoryResponse[]> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};

export const putUserPoint = (token: string | undefined, userId: string | undefined, credit: number) => {
  if (!token || !userId) {
    throw new Error("token doesn't exist");
  }
  const url = `${BASE_API_URL}/users/${userId}` as string;
  return axios
    .put<iUserResponse>(
      url,
      { credit },
      {
        headers: getHeader(token),
      }
    )
    .then((result) => result.data ?? []);
};
