import { devtools, persist } from 'zustand/middleware';

import { create } from 'zustand';

interface LoginState {
  isLogin: boolean;
  clientId?: string;
  userToken?: LoginResponse;
  sellerId?: string;
  setLogin: (userToken: LoginState['userToken']) => void;
  setLogout: () => void;
}

let token = localStorage.getItem('userToken');
// console.log('localstorage token', token);
const defaultValue = {
  isLogin: !!token,
  userToken: token ? JSON.parse(token) : undefined,
  sellerId: 'CS:601L2S8F',
};

export const useLoginStore = create<LoginState>()(
  devtools(
    persist(
      (set) => ({
        ...defaultValue,
        setLogin: (userToken: LoginState['userToken']) => {
          if (userToken) {
            localStorage.setItem('token', JSON.stringify(userToken));
            set({ isLogin: true, userToken, sellerId: 'CS:601L2S8F' });
          } else {
            set({ ...defaultValue });
          }
        },
        setLogout: () => set({ ...defaultValue }),
      }),
      {
        name: 'bear-storage',
      }
    )
  )
);
