import { Box, Typography, alpha, styled, useTheme } from '@mui/material';

import HeaderButtons from './Buttons';
import Logo from '@/components/LogoSign';
import Menu from './Menu';
import { useLoginStore } from '@/context/authStore';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background as string, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const theme = useTheme();
  const { setLogout } = useLoginStore();
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        boxShadow: `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(
          theme.colors.alpha.black[100],
          0.1
        )}`,
      }}
    >
      <Box display="flex" alignItems="center" flexGrow={1} justifyContent={{ xs: 'space-between', md: 'flex-start' }}>
        <Logo />
        <Menu onLogout={setLogout} />
      </Box>

      <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
        <Typography>Hi blue! </Typography>
        <HeaderButtons onLogout={setLogout} />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
