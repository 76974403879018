import { Divider, Link, List, Typography } from '@mui/material';

import { Box } from '@mui/system';
import OrderSummaryCard from '@/components/OrderSummaryCard';
import { iOrderResponse } from '@/types/types.definition';

type IProps = {
  items: iOrderResponse[];
  searchValue: string;
};

function SearchResult({ items, searchValue }: IProps) {
  return (
    <>
      <Box sx={{ pt: 0, pb: 1 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" component="span">
          Search results for{' '}
          <Typography sx={{ fontWeight: 'bold' }} variant="body1" component="span">
            {searchValue}
          </Typography>
        </Typography>
        <Typography variant="body2">{items.length ?? '0'} Result</Typography>
      </Box>
      {!!items?.length && (
        <>
          <Divider sx={{ my: 1 }} />
          <List disablePadding>
            {items.map((item) => (
              <Link key={item.id} href={`/order/${item.id}`}>
                <OrderSummaryCard item={item} fullWidth />
              </Link>
            ))}
          </List>
        </>
      )}
      <Divider sx={{ mt: 1, mb: 2 }} />
    </>
  );
}

export default SearchResult;
