import { POINT_TYPE } from '@/data/constant';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { iUserResponse } from '@/types/types.definition';

type ValueOf<T> = T[keyof T];
interface PointState {
  isNoData: boolean;
  activeStep: number;
  tempData?: {
    point?: string;
    mobile?: string;
    type: ValueOf<typeof POINT_TYPE>;
  };
  savePointData?: iUserResponse;
  drawerOpen: boolean;
  searchedNumber?: string;
  users?: iUserResponse[];
  selectedUser?: iUserResponse;
  setUsers: (searchedNumber: string, users: iUserResponse[] | iUserResponse) => void;
  setSelectedUser: (selectedUser: iUserResponse) => void;
  setTempData: (temp: PointState['tempData']) => void;
  setDrawerOpen: (drawerOpen: boolean) => void;
  setIsNoData: (isNoData: boolean) => void;
  setSavePointData: (savePointData: iUserResponse) => void;
  setActiveStep: (activeStep: number) => void;
}

export const usePointStore = create<PointState>()(
  devtools((set) => ({
    activeStep: 0,
    drawerOpen: false,
    tempData: undefined,
    isNoData: false,
    users: undefined,
    selectedUser: undefined,
    searchedNumber: undefined,
    savePointData: undefined,
    setUsers: (searchedNumber: string, users: iUserResponse[] | iUserResponse) => {
      if (Array.isArray(users)) {
        if (users.length === 0) {
          set({
            isNoData: true,
            users: undefined,
            drawerOpen: false,
            selectedUser: undefined,
            searchedNumber,
          });
        } else {
          set({
            users,
            searchedNumber,
            drawerOpen: true,
            selectedUser: undefined,
          });
        }
      } else {
        set({
          selectedUser: users,
          activeStep: 1,
          searchedNumber,
          drawerOpen: false,
        });
      }
    },
    setActiveStep: (activeStep) => {
      set({ activeStep });
    },
    setSelectedUser: (selectedUser) => {
      set({ selectedUser });
    },
    setTempData: (tempData) => {
      set({ tempData });
    },
    setDrawerOpen: (drawerOpen) => {
      set({ drawerOpen });
    },
    setIsNoData: (isNoData) => {
      set({ isNoData });
    },
    setSavePointData: (savePointData) => {
      set({ savePointData });
    },
  }))
);
