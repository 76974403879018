import { useNavigate, useSearchParams } from 'react-router-dom';

import SuspenseLoader from '@/components/SuspenseLoader';
import { useCanpassLogin } from '@/features/Authentication/hooks';
import { useEffect } from 'react';
import { useLoginStore } from '@/context/authStore';

const LoginCallbackPage = () => {
  const store = useLoginStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');
  console.log('login callback', code);
  const { data, isError } = useCanpassLogin(code);
  console.log('login callback', data);
  useEffect(() => {
    if (data?.access_token) {
      store.setLogin(data);
      navigate('/');
    }
  }, [data]);

  useEffect(() => {
    if (isError || !code) {
      navigate('/login');
    }
  }, [isError, code]);

  return <SuspenseLoader />;
};

export default LoginCallbackPage;
