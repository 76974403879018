import { Box } from '@mui/material';
import Logout from './Logout';

type Props = {
  onLogout(): void;
};

function HeaderButtons({ onLogout }: Props): React.ReactElement {
  return (
    <Box sx={{ mr: 1 }}>
      <Logout onClick={onLogout} />
    </Box>
  );
}

export default HeaderButtons;
