import * as React from 'react';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { NumberPad, UserPointDetail, useGetUserInfoByPhoneMemo, useSaveUserPoint } from '@/features/Point';
import { POINT_ERROR_TEXT, POINT_STEP, POINT_TYPE } from '@/data/constant';

import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone';
import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';
import { Stack } from '@mui/system';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useNavigate } from 'react-router-dom';
import { usePointStore } from '@/context/pointStore';

type ClickHandler = (value?: string) => (e: React.MouseEvent) => void;
type ValueOf<T> = T[keyof T];

export default function PointPage() {
  const theme = useTheme();
  const {
    isNoData,
    activeStep,
    selectedUser,
    searchedNumber,
    tempData,
    savePointData,
    setDrawerOpen,
    setIsNoData,
    setTempData,
    setActiveStep,
  } = usePointStore();
  const [type, setType] = React.useState<ValueOf<typeof POINT_TYPE>>(POINT_TYPE.EARN);
  const [mobile, setMobile] = React.useState('');
  const [point, setPoint] = React.useState('');
  const [isError, setIsError] = React.useState<keyof typeof POINT_ERROR_TEXT | undefined>(undefined);

  const navigate = useNavigate();
  const isPoint = React.useMemo(() => activeStep === 1, [activeStep]);
  const isTypeEarn = React.useMemo(() => type === POINT_TYPE.EARN, [type]);

  const { refetch: refetchSearchPhone, isFetching } = useGetUserInfoByPhoneMemo(mobile);

  const handleAfterSavePoint = () => {
    setActiveStep(2);
  };

  const { data, saveUserPoint, isLoading } = useSaveUserPoint({
    onSuccess: handleAfterSavePoint,
  });

  React.useEffect(() => {
    if (searchedNumber !== mobile) {
      setDrawerOpen(false);
      setIsNoData(false);
    }
  }, [searchedNumber, mobile]);

  React.useEffect(() => {
    if (tempData) {
      setMobile(tempData.mobile ?? '');
      setPoint(tempData.point ?? '');
      setType(tempData.type);
      setTempData(undefined);
    }
  }, [tempData]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setIsError(undefined);
  };

  const handleReset = () => {
    setActiveStep(0);
    setMobile('');
    setPoint('');
    setType(POINT_TYPE.EARN);
    setTempData(undefined);
  };

  const handleClickType: ClickHandler = (value?: string) => (e) => {
    e.preventDefault();
    console.log(type);

    if (value) {
      setType(value);
      return;
    }

    setType((_type) => (_type === POINT_TYPE.EARN ? POINT_TYPE.SPEND : POINT_TYPE.EARN));
  };

  const handleHistoryOpen = () => {
    if (selectedUser) {
      setTempData({ point, mobile, type });
      navigate(`/point/${selectedUser.id}`);
    }
  };

  const handleChangeValue = (value: string) => {
    if (value === 'OK') {
      if (isPoint) {
        if (!point || point === '0') return setIsError('POINT');
        saveUserPoint(Number(isTypeEarn ? point : -point));
      } else {
        if (!mobile) {
          return setIsError('PHONE');
        }
        if (mobile) {
          refetchSearchPhone();
        }
      }
      return;
    }

    if (value === 'CLEAR') {
      if (isPoint) setPoint('');
      else setMobile('');
      return;
    }

    if (isPoint) setPoint((_point) => (value === '0' && _point.length === 0 ? _point : _point + value));
    else setMobile((_point) => _point + value);
  };

  const handleClickBackspace = () => {
    if (isPoint) setPoint((_point) => _point.slice(0, -1));
    else setMobile((_mobile) => _mobile.slice(0, -1));
  };

  return (
    <>
      {(isLoading || isFetching) && <SuspenseLoader />}
      <Box display="flex" flexDirection="column" flex="1">
        <Box mb={2}>
          <Typography variant="h3" mb={1}>
            Points
          </Typography>
          {!!activeStep && (
            <Box display="flex" justifyContent="flex-end">
              <Button color="inherit" onClick={handleHistoryOpen}>
                <Typography variant="h6" color="GrayText">
                  History &#62;
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent={['center', 'flex-start']}>
          <Box
            sx={{
              width: '100%',
              maxWidth: theme.breakpoints.values.md,
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Stepper activeStep={activeStep}>
                {POINT_STEP.map((label) => {
                  const stepProps: { completed?: boolean } = activeStep === 2 ? { completed: true } : {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {activeStep === 2 ? (
                <UserPointDetail
                  point={isTypeEarn ? point : `-${point}`}
                  data={data ?? savePointData}
                  onResetStep={handleReset}
                />
              ) : (
                <React.Fragment>
                  <Box sx={{ flex: '1 1 auto' }} mt={3}>
                    <Box
                      sx={{
                        mb: 1,
                        opacity: isPoint ? 1 : 0,
                      }}
                    >
                      {
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          <div>
                            <Button color="primary" onClick={handleClickType(POINT_TYPE.EARN)}>
                              + EARN
                            </Button>
                            <Button color="error" onClick={handleClickType(POINT_TYPE.SPEND)}>
                              - SPEND
                            </Button>
                          </div>
                        </Stack>
                      }
                    </Box>
                    <Stack direction={['column', 'row']} gap={[2, 5]}>
                      <Box sx={{ width: 1 }}>
                        <TextField
                          id="outlined-number"
                          label={POINT_STEP[activeStep]}
                          value={isPoint ? point : mobile}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={isNoData || !!isError}
                          helperText={isNoData ? POINT_ERROR_TEXT.NO_DATA : POINT_ERROR_TEXT[isError!]}
                          InputProps={{
                            readOnly: true,
                            startAdornment: isPoint ? (
                              <InputAdornment position="start">
                                <IconButton aria-label="toggle earn, spend" onClick={handleClickType()}>
                                  {isTypeEarn ? (
                                    <AddBoxTwoToneIcon color="primary" />
                                  ) : (
                                    <IndeterminateCheckBoxTwoToneIcon color="error" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton aria-label="remove" onClick={handleClickBackspace}>
                                  <BackspaceTwoToneIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Box>
                      <NumberPad onClick={handleChangeValue} />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: activeStep === 0 ? 'none' : 'flex',
                      flexDirection: 'row',
                      pt: 2,
                    }}
                  >
                    <Button color="inherit" onClick={handleBack}>
                      &#60; Back
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
