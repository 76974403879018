import { getCanpassToken } from '../services/auth';
import { useQuery } from '@tanstack/react-query';

export const useCanpassLogin = (code: string | null) => {
  if (!code) {
    return { isError: false };
  }
  const { isError, data, error } = useQuery({
    queryKey: ['moimLogin', code],
    queryFn: () => getCanpassToken(code),
  });
  return { isError, data, error };
};
