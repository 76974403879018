import { Button } from '@mui/material';
import { Stack } from '@mui/system';

type Props = {
  onCancelDelete: () => void;
  onDelete: () => void;
  itemLength: number;
};

const DeleteAndCancelButtons = ({ onCancelDelete, onDelete, itemLength }: Props) => {
  return (
    <Stack direction="row" spacing={[2, 6]} sx={{ width: ['100%', '90%'] }}>
      <Button variant="contained" color="inherit" fullWidth onClick={onCancelDelete}>
        Cancel
      </Button>
      <Button variant="contained" color="error" fullWidth onClick={onDelete} disabled={itemLength === 0}>
        Delete {itemLength ? `(${itemLength})` : ''}
      </Button>
    </Stack>
  );
};

export default DeleteAndCancelButtons;
