import { Box, alpha, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

import CustomizedSnackbar from '@/components/CustomizedSnackbar';
import { Outlet } from 'react-router-dom';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          position: 'relative',
          flexDirection: 'column',
          '.MuiPageTitle-wrapper': {
            background: theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow: `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.1)}, 0px 5px 12px -4px ${alpha(
              theme.colors.alpha.black[100],
              0.05
            )}`,
          },
        }}
      >
        {children || <Outlet />}
      </Box>
      <CustomizedSnackbar />
    </>
  );
};

export default BaseLayout;
