import { AddressForm, CustomerInfoForm, IFormAddressInput, IFormCustomerInfoInput } from '@/features/Request';
import { iPandagoRiderRequest, iRecipientInfo } from '@/types/types.definition';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetSellerInfo, usePostOfflineRequestRider, usePostOnlineRequestRider } from '@/features/Request/hooks';

import { AlertType } from '@/types/enums';
import { Box } from '@mui/material';
import RequestSummary from '../features/Request/RequestSummary';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useAlertStore } from '@/context/alertStore';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useOrderStore } from '@/context/orderStore';

export default function NewRequestPage() {
  const navigate = useNavigate();
  const { sellerAddress, sellerInfo } = useGetSellerInfo();
  const { selectedOrder, setSelectedOrder } = useOrderStore();
  const { setOpen } = useAlertStore();
  const { requestRider, isLoading, isSuccess, isError, error } = usePostOfflineRequestRider();
  const {
    onlineRequestRider,
    isLoading: isOnlineRequestLoading,
    isSuccess: isOnlineRequestSuccess,
    isError: isOnlineRequestError,
    error: onlineRequestError,
  } = usePostOnlineRequestRider();
  const [step, setStep] = useState(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [infoData, setInfoData] = useState<IFormCustomerInfoInput | null>(null);
  const [addressData, setAddressData] = useState<IFormAddressInput | null>(null);

  const { control, formState, handleSubmit } = useForm<IFormCustomerInfoInput>({
    defaultValues: {
      buyerName: '',
      buyerPhone: '',
      memo: '',
    },
  });

  const { dirtyFields } = formState;

  const {
    control: addressControl,
    handleSubmit: handleAddressSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: addressFormState,
  } = useForm<IFormAddressInput>({
    defaultValues: {
      zipCode: '',
      address: '',
      unit: '',
    },
  });

  const { dirtyFields: addressDirtyFields } = addressFormState;

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0 || Object.keys(addressDirtyFields).length > 0) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [dirtyFields]);

  useEffect(() => {
    const handleWindowBeforeUnload = (event: BeforeUnloadEvent) => {
      handleBeforeUnload(event);
    };

    window.addEventListener('beforeunload', handleWindowBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleWindowBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (isError || isOnlineRequestError) {
      setOpen(true, AlertType.ERROR, `${(error || onlineRequestError) ?? 'Something wrong try again!'}`);
    }
  }, [isError, isOnlineRequestError]);

  useEffect(() => {
    if (isSuccess || isOnlineRequestSuccess) {
      setOpen(true, AlertType.SUCCESS, `Request rider is success!`);
      navigate('/tracking');
      setSelectedOrder(undefined);
    }
  }, [isSuccess, isOnlineRequestSuccess]);

  useEffect(() => {
    if (selectedOrder) {
      setStep(2);
    }
  }, [selectedOrder]);

  const checkMobileNumber = useCallback((mobile?: string) => {
    if (!mobile) return;
    if (mobile.startsWith('+')) {
      return mobile.substring(4);
    }
    return mobile;
  }, []);

  const handleSetData = (info: IFormCustomerInfoInput) => {
    console.log(infoData);
    setInfoData(info);
    setStep(1);
  };

  const handleSetAddressData = (address?: IFormAddressInput) => {
    console.log(addressData);
    if (address) {
      setAddressData(address);
    }

    setStep(2);
  };

  const handleStepChange = (step: number) => {
    setStep(step);
  };

  const requestInfo: iRecipientInfo | undefined = useMemo(() => {
    if (infoData && addressData) {
      return {
        name: infoData.buyerName,
        phone: infoData.buyerPhone,
        notes: infoData.memo,
        location: {
          address: `${addressData.address} ${addressData?.unit}`,
          postalcode: addressData.zipCode,
        },
      };
    }
  }, [infoData, addressData]);

  const handleOfflineRequestRider = () => {
    if (!requestInfo) return;

    const requestData: iPandagoRiderRequest = {
      Pandago: {
        sender: sellerInfo!,
        recipient: {
          ...requestInfo,
          phone: `+65${checkMobileNumber(requestInfo.phone)}`,
        },
      },
    };

    requestRider(requestData);
  };

  const handleRequestRider = () => {
    if (selectedOrder) {
      const requestData = {
        id: selectedOrder.id,
        Pandago: {
          sender: sellerInfo!,
          recipient: selectedOrder.recipient!,
        },
      };
      onlineRequestRider(requestData);
    }

    if (infoData && addressData) {
      handleOfflineRequestRider();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {(isLoading || isOnlineRequestLoading) && <SuspenseLoader />}
      {step === 0 ? (
        <CustomerInfoForm control={control} onHandleSubmit={handleSubmit} onSetData={handleSetData} />
      ) : step === 1 ? (
        <AddressForm
          collectionPointAddress={sellerAddress}
          control={addressControl}
          onHandleSubmit={handleAddressSubmit}
          onSetData={handleSetAddressData}
          onGetValues={getValues}
          onSetValues={setValue}
          onSetError={setError}
          onClearError={clearErrors}
        />
      ) : (
        <RequestSummary
          showEditButton={!!addressData}
          buyerInfo={
            infoData
              ? { orderId: '#OFFLINE_ORDER', ...infoData }
              : {
                  orderId: selectedOrder?.id!,
                  buyerName: selectedOrder?.Buyer.name!,
                  buyerPhone: selectedOrder?.Buyer.phone!,
                  memo: selectedOrder?.recipient?.notes!,
                }
          }
          dropOffInfo={
            addressData ?? {
              recipientName: selectedOrder?.recipient?.name,
              recipientPhone: selectedOrder?.recipient?.phone,
              zipCode: selectedOrder?.recipient?.location.postalcode!,
              address: selectedOrder?.recipient?.location.address!,
            }
          }
          onStepChange={handleStepChange}
          onRequestNewRider={handleRequestRider}
        />
      )}
    </Box>
  );
}
