import { Box } from '@mui/system';
import Clock from 'react-live-clock';
import { Search } from '@/features/Orders';
import { Typography } from '@mui/material';

function OrderTrackingTitle() {
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">Order Tracking</Typography>
        <Search />
      </Box>
      <Clock ticking format={'LLLL'} />
    </Box>
  );
}

export default OrderTrackingTitle;
