import { CANPASS_AUTH_URL, CLIENT_ID, PLUGIN_URL } from '@/data/constant';

import axios from 'axios';
import qs from 'query-string';

export const getCanpassToken = (code: string) => {
  const url = `${CANPASS_AUTH_URL}/token` as string;
  return axios
    .post(
      url,
      qs.stringify({
        code,
        client_id: CLIENT_ID,
        grant_type: 'authorization_code',
        redirect_uri: `${PLUGIN_URL}/canpass/callback`,
      })
    )
    .then((result): Promise<LoginResponse> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};
