import { Box, Link, Typography, TypographyProps } from '@mui/material';

const Copyright = (props: TypographyProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 1,
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/" sx={{ mr: 1 }}>
          blue basket
        </Link>
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};

export default Copyright;
