import { iOrderResponse, iSenderInfo } from '@/types/types.definition';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface OrderState {
  orders: iOrderResponse[];
  selectedOrder?: iOrderResponse;
  sellerInfo?: iSenderInfo;
  setOrders: (orders: iOrderResponse[]) => void;
  setSelectedOrder: (order?: iOrderResponse) => void;
  setSellerInfo: (sellerInfo: OrderState['sellerInfo']) => void;
}

export const useOrderStore = create<OrderState>()(
  devtools((set) => ({
    sellerInfo: undefined,
    orders: [],
    selectedOrder: undefined,
    setOrders: (orders: iOrderResponse[]) => {
      set({ orders });
    },
    setSelectedOrder: (order?: iOrderResponse) => {
      set({ selectedOrder: order });
    },
    setSellerInfo: (sellerInfo: OrderState['sellerInfo']) => {
      set({ sellerInfo });
    },
  }))
);
