import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import Brightness1Icon from '@mui/icons-material/Brightness1';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { dateFormat } from '@/utils/date';
import { iUserResponse } from '@/types/types.definition';

type AccordionType = {
  order: boolean;
  customer: boolean;
};

type Props = {
  point: string;
  data?: iUserResponse;
  onResetStep(): void;
};

function UserPointDetail({ data, point, onResetStep }: Props) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<AccordionType>({
    order: false,
    customer: true,
  });

  const handleAccordionChange = (name: keyof AccordionType) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((state) => ({ ...state, [name]: isExpanded }));
  };

  return (
    <>
      <Container maxWidth="lg">
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
          }}
        >
          {' '}
          {data ? (
            <>
              <CardHeader
                title={
                  <Box>
                    <Typography variant="h6" color="GrayText">
                      TOTAL POINTS
                    </Typography>
                    <Typography variant="h3"> {data.availableCredit} P</Typography>
                    <Typography variant="h6" color="GrayText">
                      ACCUMULATION POINT
                      <Typography
                        variant="h5"
                        component="span"
                        color="black"
                        sx={{
                          ml: 2,
                        }}
                      >
                        {point} P
                      </Typography>
                    </Typography>
                  </Box>
                }
                subheader={
                  <Stack direction="row" alignItems="center" mt={1}>
                    <Brightness1Icon sx={{ typography: 'body1', mr: 1 }} />
                    Number of purchases: {data.paymentCount ?? 0}
                  </Stack>
                }
                subheaderTypographyProps={{
                  variant: 'h6',
                  color: 'black',
                }}
                sx={{ borderBottom: 1 }}
              />
              <CardContent>
                <Box textAlign="end" mb={theme.spacing(2)}>
                  <Typography variant="caption">Date</Typography>
                  <Typography variant="h6">{dateFormat(data?.updatedAt)}</Typography>
                </Box>
                <Accordion expanded={expanded.customer} onChange={handleAccordionChange('customer')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4">Customer's Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack>
                      <Box mb={theme.spacing(2)}>
                        <Typography variant="caption">NAME</Typography>
                        <Typography variant="h6">{data.name}</Typography>
                      </Box>
                      <Box mb={theme.spacing(2)}>
                        <Typography variant="caption">MOBILE NUMBER</Typography>
                        <Typography variant="h6">{data.phone}</Typography>
                      </Box>
                      <Box mb={theme.spacing(2)}>
                        <Typography variant="caption">EMAIL</Typography>
                        <Typography variant="h6">{data.email}</Typography>
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </>
          ) : (
            <Typography>Error occur</Typography>
          )}
          <Box m={theme.spacing(2)}>
            <Button variant="outlined" fullWidth onClick={onResetStep}>
              Back to First Step
            </Button>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default UserPointDetail;
