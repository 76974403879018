import { getSellerInfo, postOfflineRequestRider, putOnlineRequestRider } from '../service';
import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { OnlineOrderRequest } from '../';
import { iPandagoRiderRequest } from '@/types/types.definition';
import { useLoginStore } from '@/context/authStore';
import { useOrderStore } from '@/context/orderStore';

export const useGetSellerInfo = () => {
  const { userToken } = useLoginStore();
  const { sellerInfo, setSellerInfo } = useOrderStore();
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ['sellerInfo'],
    queryFn: () => getSellerInfo(userToken?.access_token),
  });
  const sellerAddress = useMemo(() => {
    return `(${sellerInfo?.location.postalcode}) ${sellerInfo?.location.address}` ?? '';
  }, [sellerInfo]);

  useEffect(() => {
    if (data) {
      setSellerInfo(data);
    }
  }, [data]);

  return { isLoading, isError, sellerInfo, error, refetch, sellerAddress };
};

export const usePostOfflineRequestRider = () => {
  const { userToken } = useLoginStore();

  const {
    mutateAsync: requestRider,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation({
    mutationFn: (requestData: iPandagoRiderRequest) => {
      return postOfflineRequestRider(userToken?.access_token, requestData);
    },
  });

  return { requestRider, isLoading, isSuccess, isError, error };
};

export const usePostOnlineRequestRider = () => {
  const { userToken } = useLoginStore();

  const {
    mutate: onlineRequestRider,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation({
    mutationFn: (data: Partial<OnlineOrderRequest>) => {
      const _data = (data && data) ?? {};
      return putOnlineRequestRider({ ..._data }, userToken?.access_token, data.id);
    },
  });

  return { onlineRequestRider, isLoading, isSuccess, isError, error };
};
