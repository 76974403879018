import { Box, Stack } from '@mui/system';
import { Card, CardContent, Link, Typography, useTheme } from '@mui/material';

import DeliveryDiningTwoToneIcon from '@mui/icons-material/DeliveryDiningTwoTone';
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone';
import { TEXT } from '@/data/constant';

const SelectMenuPage = () => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
      <Stack direction={['column', 'row']} gap={3}>
        <Link href="/tracking" underline="none">
          <Card
            variant="outlined"
            sx={{
              backgroundColor: theme.colors.primary.lighter,
              borderColor: theme.colors.primary.light,
            }}
          >
            <CardContent
              sx={{
                width: 250,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DeliveryDiningTwoToneIcon sx={{ fontSize: 100, color: theme.colors.primary.light }} />
              <Typography
                variant="h3"
                sx={{
                  color: theme.colors.primary.dark,
                }}
              >
                {TEXT.REQUEST_RIDER}
              </Typography>
            </CardContent>
          </Card>
        </Link>
        <Link href="/point" underline="none">
          <Card
            variant="outlined"
            sx={{
              backgroundColor: theme.colors.warning.lighter,
              borderColor: theme.colors.warning.light,
            }}
          >
            <CardContent
              sx={{
                width: 250,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PointOfSaleTwoToneIcon color="secondary" sx={{ fontSize: 100, color: theme.colors.warning.main }} />
              <Typography
                variant="h3"
                sx={{
                  color: theme.colors.warning.dark,
                }}
              >
                {TEXT.POINT_SERVICE}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Stack>
    </Box>
  );
};

export default SelectMenuPage;
