import { CANPASS_AUTH_URL, CLIENT_ID, PLUGIN_URL } from '@/data/constant';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import { getAuthorizationUrl } from '@/data/constant';
import Copyright from '@/layouts/components/Copyright';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLoginStore } from '@/context/authStore';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const { userToken } = useLoginStore();
  const handleClick = () => {
    const params = {
      client_id: CLIENT_ID,
      scope: 'email',
      redirect_uri: `${PLUGIN_URL}/canpass/callback`,
      action: 'signin',
      state: 'bluebasket',
    };
    location.href = `${CANPASS_AUTH_URL}?${queryString.stringify(params)}`;
  };

  useEffect(() => {
    if (userToken) {
      navigate('/');
    }
  }, [userToken]);

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ width: '100%', mt: 1 }}>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClick}>
              Can pass Login
            </Button>
          </Box>
        </Box>
      </Container>
      <Copyright
        sx={{
          mt: 8,
          mb: 4,
        }}
      />
    </>
  );
};

export default LoginPage;
