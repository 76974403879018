import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IconButton } from '@mui/material';

type Props = {
  showButton: boolean;
  onChangeEdit(): void;
};

export default function OrderEditButton({ showButton, onChangeEdit }: Props) {
  if (!showButton) return null;

  return (
    <IconButton onClick={onChangeEdit}>
      <DeleteTwoToneIcon color="error" />
    </IconButton>
  );
}
