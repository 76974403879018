import { deleteOrders, getOrderDetail, getOrderStat, getOrders, getSearchedKeywords } from '../services';
import { useMutation, useQuery } from '@tanstack/react-query';

import { eDeliveryStatus } from '@/types/enums';
import { iOrderResponse } from '@/types/types.definition';
import { useEffect } from 'react';
import { useLoginStore } from '@/context/authStore';
import { useOrderStore } from '@/context/orderStore';

export const useGetOrderStat = () => {
  const { userToken } = useLoginStore();

  const { isError, data, error, refetch, isLoading, isFetching, fetchStatus } = useQuery({
    queryKey: ['orderStat'],
    queryFn: () => getOrderStat(userToken?.access_token),
  });
  return { isError, data, error, refetch, isLoading, isFetching, fetchStatus };
};

export const useGetOrders = (status: eDeliveryStatus) => {
  const { userToken } = useLoginStore();

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ['orders', status],
    queryFn: () =>
      getOrders(userToken?.access_token!, {
        status,
      }),
  });

  return { isLoading, isError, data, error, refetch };
};

export const useGetOrderDetail = (orderId?: string) => {
  const { userToken } = useLoginStore();
  const { setSelectedOrder } = useOrderStore();

  const { isLoading, isError, data, error, refetch } = useQuery<iOrderResponse>({
    queryKey: ['order', orderId],
    queryFn: () => getOrderDetail(userToken?.access_token, orderId).then((result) => result.data),
  });

  useEffect(() => {
    setSelectedOrder(data);
  }, [data]);

  return { isLoading, isError, data, error, refetch };
};

export const useDeleteOrder = () => {
  const { userToken } = useLoginStore();

  const { isLoading, isError, error, isSuccess, mutate, data } = useMutation((ids: string[]) =>
    deleteOrders(ids, userToken?.access_token)
  );

  return { isLoading, isError, mutate, error, isSuccess, data };
};

export const useGetOrdersSearch = (searchValue: string) => {
  const { userToken } = useLoginStore();

  const { isLoading, isError, data, error, isFetched } = useQuery({
    queryKey: ['search', searchValue],
    queryFn: () =>
      getOrders(userToken?.access_token!, {
        keyword: searchValue,
      }),
    enabled: Boolean(searchValue),
  });

  return { isLoading, isError, data, error, isFetched };
};

export const useGetSearchKeywords = () => {
  const { userToken } = useLoginStore();

  const {
    isLoading,
    isError,
    data: keywords,
    error,
  } = useQuery({
    queryKey: ['searchKeywords'],
    queryFn: () => getSearchedKeywords(userToken?.access_token),
  });

  return { isLoading, isError, keywords, error };
};
