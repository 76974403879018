import { eDeliveryStatus } from '@/types/enums';

export const checkDeleteStatus = (status: eDeliveryStatus) => {
  switch (status) {
    case eDeliveryStatus.WAITING:
    case eDeliveryStatus.REQUESTED:
      return true;

    default:
      return false;
  }
};
