import { Button, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { OrderList, OrderTracking } from '@/features/Orders';

import SuspenseLoader from '@/components/SuspenseLoader';
import { eDeliveryStatus } from '@/types/enums';
import { useGetOrders } from '@/features/Orders/hooks';

function OrderTrackingPage() {
  const theme = useTheme();
  const { data, isLoading } = useGetOrders(eDeliveryStatus.WAITING);
  const getLimit = useMediaQuery(theme.breakpoints.up('md')) ? 4 : 2;
  return (
    <Grid container item direction="row" justifyContent="center" alignItems="stretch" md>
      <Grid container item direction="column" xs={12}>
        <OrderTracking />
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Grid item>
          <Typography variant="h3">Recent Orders</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          {isLoading ? (
            <SuspenseLoader />
          ) : (
            <OrderList items={data ?? []} isEdit={false} limit={getLimit} disabledSelected />
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" item gap={2} sx={{ mt: 4, px: 1 }}>
        <Link href="/requests" underline="none">
          <Button variant="contained" color="info" fullWidth>
            + Request Order
          </Button>
        </Link>
        <Link href="/requests/new" underline="none">
          <Button variant="outlined" color="info" fullWidth>
            + Create new Delivery
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default OrderTrackingPage;
