import { Button, Card, TextField, Typography } from '@mui/material';
import {
  Control,
  Controller,
  SubmitHandler,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

import { Box } from '@mui/system';
import { IFormAddressInput } from '../';
import { getAddressByPostCode } from '../service/index';

type Props = {
  collectionPointAddress: string;
  control: Control<IFormAddressInput>;
  onHandleSubmit: UseFormHandleSubmit<IFormAddressInput>;
  onSetData(data: IFormAddressInput): void;
  onGetValues: UseFormGetValues<IFormAddressInput>;
  onSetValues: UseFormSetValue<IFormAddressInput>;
  onSetError: UseFormSetError<IFormAddressInput>;
  onClearError: UseFormClearErrors<IFormAddressInput>;
};
export default function AddressForm({
  collectionPointAddress,
  control,
  onHandleSubmit,
  onSetData,
  onGetValues,
  onSetValues,
  onSetError,
  onClearError,
}: Props) {
  const onSubmit: SubmitHandler<IFormAddressInput> = (data) => {
    console.log(data);
    onSetData(data);
  };

  const handleSetAddress = async () => {
    const value = onGetValues('zipCode');
    console.log(value);
    if (!value) {
      onSetError('zipCode', { type: 'required', message: 'post code is required' }, { shouldFocus: true });
      return;
    }
    onClearError('zipCode');

    const result = await getAddressByPostCode(value);

    if (result.data.found > 0) {
      const address = result.data.results?.[0];
      onSetValues('address', `${address?.BLK_NO} ${address?.ROAD_NAME} ${address?.BUILDING}`);
      onSetValues('zipCode', address?.POSTAL);
    } else {
      onSetError('zipCode', { type: 'notFound', message: "address isn't found" }, { shouldFocus: true });
    }
  };

  return (
    <Card sx={{ maxWidth: 'lg', p: 3 }}>
      <Box display="flex" flexDirection="column" gap={3} mb={5}>
        <Typography variant="h3">Collection point</Typography>
        <TextField label={collectionPointAddress} variant="standard" disabled />
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mb={10}>
        <Typography variant="h3">Customer drop off</Typography>
        <Box display="flex" sx={{ flex: '1 1 auto' }}>
          <Controller
            rules={{
              required: 'post code is required',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                type="number"
                label="Post code"
                {...field}
                error={!!error}
                helperText={!!error && error?.message}
              />
            )}
            name="zipCode"
            control={control}
          />
          <Button onClick={handleSetAddress}>Find Address</Button>
        </Box>
        <Controller
          rules={{
            required: 'address is required',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField label="Address" error={!!error?.message} helperText={error?.message} {...field} />
          )}
          name="address"
          control={control}
        />
        <Controller
          render={({ field }) => <TextField label="Unit Number" {...field} />}
          name="unit"
          control={control}
        />
      </Box>
      <Button variant="outlined" fullWidth onClick={onHandleSubmit(onSubmit)}>
        Save
      </Button>
    </Card>
  );
}
