import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';

type Props = {
  open: boolean;
  onClose(): void;
  onClick(reason: string): void;
  title: string;
  confirmText?: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CANCEL_RIDER_RADIO = {
  INCORRECT: 'Incorrect order details',
  CANCEL: 'Customer cancelled order',
  OTHER: 'Other delivery method became available',
};

function CancelRiderModal({ open, onClose, onClick, title, confirmText }: Props) {
  const [reason, setReason] = React.useState(CANCEL_RIDER_RADIO.INCORRECT);
  const theme = useTheme();
  const handleOnClick = () => {
    onClick(reason);
  };

  const handleChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ m: 0, p: 1 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (_theme) => _theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}

          <Typography variant="h4">{title}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id="cancel-rider-radio-buttons-group-label" sx={{ my: 2 }}>
            <Typography variant="h3">Do you want to cancel the rider?</Typography>
            Select a reason why you want to cancel the rider
          </FormLabel>
          <RadioGroup
            aria-labelledby="cancel-rider-radio-buttons-group-label"
            defaultValue={CANCEL_RIDER_RADIO.INCORRECT}
            name="radio-buttons-group"
            onChange={handleChangeRadioButton}
          >
            <Box display="flex" flexDirection="column" gap={theme.spacing(3)}>
              <FormControlLabel
                value={CANCEL_RIDER_RADIO.INCORRECT}
                control={<Radio />}
                label={CANCEL_RIDER_RADIO.INCORRECT}
              />
              <FormControlLabel
                value={CANCEL_RIDER_RADIO.CANCEL}
                control={<Radio />}
                label={CANCEL_RIDER_RADIO.CANCEL}
              />
              <FormControlLabel value={CANCEL_RIDER_RADIO.OTHER} control={<Radio />} label={CANCEL_RIDER_RADIO.OTHER} />
            </Box>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOnClick} autoFocus size="large" fullWidth>
          {confirmText ?? 'CANCEL RIDER'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelRiderModal;
