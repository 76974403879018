import { iKeywordResponse, iOrderResponse, iStatResponse } from '@/types/types.definition';

import { BASE_API_URL } from '@/data/constant';
import axios from 'axios';
import { getHeader } from '@/services';

export const getOrderStat = (token?: string) => {
  const url = `${BASE_API_URL}/stats` as string;

  if (!token) {
    throw new Error('token does not exist');
  }

  return axios
    .get(url, {
      headers: getHeader(token),
    })
    .then((result): Promise<iStatResponse> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};

export const getOrders = (token: string, query: { [name: string]: string } = {}) => {
  if (!token) {
    throw new Error('token does not exist');
  }

  const url = `${BASE_API_URL}/orders` as string;

  return axios
    .get(url, {
      headers: getHeader(token),
      params: query,
    })
    .then((result): Promise<iOrderResponse[]> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};

export const getOrderDetail = (token?: string, orderId?: string) => {
  if (!token || !orderId) {
    throw new Error('token or orderId does not exist');
  }

  const url = `${BASE_API_URL}/orders/${orderId}` as string;

  return axios.get<iOrderResponse>(url, {
    headers: getHeader(token),
  });
};

export const deleteOrders = (ids: string[], token?: string) => {
  const url = `${BASE_API_URL}/orders` as string;

  if (!token) {
    throw new Error('token does not exist');
  }

  return axios
    .delete(url, {
      headers: getHeader(token),
      data: { ids },
    })
    .then((result) => result.data);
};

export const getSearchedKeywords = (token?: string) => {
  if (!token) {
    throw new Error('token does not exist');
  }

  const url = `${BASE_API_URL}/keywords` as string;
  return axios
    .get(url, {
      headers: getHeader(token),
    })
    .then((result): Promise<iKeywordResponse[]> => result.data)
    .catch(function (error) {
      console.log(error.toJSON());
    });
};
