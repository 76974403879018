import { AlertType, eDeliveryStatus } from '@/types/enums';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { OrderEditButton, OrderList } from '@/features/Orders';
import { useDeleteOrder, useGetOrders } from '@/features/Orders/hooks';

import Clock from 'react-live-clock';
import { DeleteAndCancelButtons } from '@/components/buttons';
import React from 'react';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useAlertStore } from '@/context/alertStore';
import { useNavigate } from 'react-router-dom';

const TABS = ['NEW', 'REQUESTED', 'ACCEPTED'];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function WaitingsPage() {
  const navigate = useNavigate();
  const [isEdit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [checkedItems, setCheckedItems] = React.useState<string[]>([]);
  const { setOpen } = useAlertStore();
  const { data: newData, isLoading: isNewLoading, refetch } = useGetOrders(eDeliveryStatus.WAITING);
  const {
    data: requestedData,
    isLoading: isRequestLoading,
    refetch: requestRefetch,
  } = useGetOrders(eDeliveryStatus.REQUESTED);
  const {
    data: acceptedData,
    isLoading: isAcceptedLoading,
    refetch: acceptedRefetch,
  } = useGetOrders(eDeliveryStatus.ACCEPTED);

  const { mutate, isSuccess, isError, error, isLoading, data } = useDeleteOrder();

  React.useEffect(() => {
    if (isError || isSuccess) {
      setOpen(
        true,
        isError ? AlertType.ERROR : AlertType.SUCCESS,
        isError ? `${error ?? 'Something wrong try again!'}` : `selected ${data?.count ?? ''} orders  are deleted`
      );
      if (isSuccess) {
        refetch();
        requestRefetch();
        acceptedRefetch();
        handleCancelDelete();
      }
    }
  }, [isError, isSuccess]);

  const dataArrays = React.useMemo(
    () => [newData, requestedData, acceptedData].filter(Boolean),
    [newData, requestedData, acceptedData]
  );

  const handleCheckedItem = (orderId: string) => {
    if (isEdit) {
      const hasCheckedItem = checkedItems.indexOf(orderId) !== -1;
      const updatedList = hasCheckedItem ? checkedItems.filter((id) => id !== orderId) : [...checkedItems, orderId];
      setCheckedItems(updatedList);
      return;
    }

    setCheckedItems(checkedItems?.[0] === orderId ? [] : [orderId]);
  };

  const handleDeleteOrders = () => {
    mutate(checkedItems);
  };

  const handleChangeEdit = () => {
    setEdit(!isEdit);
    setValue(0);
  };

  const handleCancelDelete = () => {
    setEdit(false);
    setCheckedItems([]);
  };

  const handleTabChangeValue = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleRequestOrder = () => {
    navigate('/requests/new');
  };
  return (
    <>
      {isLoading && <SuspenseLoader />}
      <Box display="flex" flexDirection="column" flex="1">
        <Box mb={3}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h3">New Orders</Typography>
            <OrderEditButton showButton={!!dataArrays?.length} onChangeEdit={handleChangeEdit} />
          </Box>
          {isEdit ? (
            <Typography>You can delete orders manually in this page</Typography>
          ) : (
            <Clock ticking format={'LLLL'} />
          )}
        </Box>
        <Tabs value={value} sx={{ mb: 3, display: 'flex', gap: 3 }} onChange={handleTabChangeValue}>
          {(isEdit ? TABS.slice(0, 1) : TABS).map((tabName, index) => (
            <Tab
              label={`${tabName} ${dataArrays[index]?.length ? `(${dataArrays[index]?.length})` : ''}`}
              key={tabName}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Box display="flex" flex="1">
          {isNewLoading || isRequestLoading || isAcceptedLoading ? (
            <SuspenseLoader />
          ) : (
            <Box display="flex" justifyContent="center" flex="1">
              <OrderList
                checkedItems={checkedItems}
                items={dataArrays[value] ?? []}
                isEdit={isEdit}
                onCheckedItem={handleCheckedItem}
                disabledSelected={value !== 0}
              />
              <Box
                sx={{
                  display: 'flex',
                  position: 'fixed',
                  bottom: '7vh',
                  width: ['90%', '50%'],
                }}
              >
                {isEdit ? (
                  <DeleteAndCancelButtons
                    onCancelDelete={handleCancelDelete}
                    onDelete={handleDeleteOrders}
                    itemLength={checkedItems.length}
                  />
                ) : (
                  value === 0 && (
                    <Button
                      variant="contained"
                      color="info"
                      fullWidth
                      onClick={handleRequestOrder}
                      disabled={checkedItems.length !== 1}
                    >
                      Request a rider
                    </Button>
                  )
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default WaitingsPage;
