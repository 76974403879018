import { Button, Card, CardContent, Stack } from '@mui/material';
import { CollectPointSummary, DropOffSummary, RequestProps } from './';

function RequestSummary({ showEditButton, dropOffInfo, buyerInfo, onStepChange, onRequestNewRider }: RequestProps) {
  return (
    <Card>
      <CardContent>
        <Stack direction="column" flex={1}>
          <CollectPointSummary buyerInfo={buyerInfo} onStepChange={onStepChange} showEditButton={showEditButton} />
          <DropOffSummary onStepChange={onStepChange} dropOffInfo={dropOffInfo} showEditButton={showEditButton} />
          <Button variant="contained" fullWidth onClick={onRequestNewRider}>
            FINISH REQUEST
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default RequestSummary;
